@import url('https://fonts.googleapis.com/css2?family=Anton&display=swap');
.cont-container{
    padding: 20px;
    margin: 30px;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media screen and (max-width:768px) {
    .cont-container{
        padding: 3px;
        margin: 5px;
    }
}

.cont-wrapper{
    width:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(179, 8, 36);
}
@media screen and (max-width:768px) {
    .cont-wrapper{
        
            flex-direction: column;
            justify-content: center;
            
    }
}
.infowrapper{
   min-width: 400px auto;
   padding: 20px;
}
@media screen and(max-width: 768px) {
    .infowrapper{
        width: auto;
    }
}
.cont-h1{
    color: rgb(252, 252, 252);
    font-size: 50px;
    font-family: 'Anton', sans-serif;
    text-align: center;
 }
 .cont-el{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    
     
 }
 @media screen and(max-width: 768px) {
    .cont-el{
        width: auto;
    }
}
 .fa-mobile{
     font-size: 1.5rem;
     color: #fff;
     padding: 5px;
 }
 .fa-whatsapp{
    font-size: 1.5rem;
    color: #fff;
    padding: 5px;
 }
 .con-text{
    font-size: 1rem;
     color: #fff;
     padding: 5px;
 }
 .fa-envelope-open-o{

    font-size: 1.5rem;
    color: #fff;
    padding: 5px;
 }
 .fa-map-pin{
    font-size: 1.5rem;
    color: #fff;
    padding: 5px;
 }
 .cont-map{
     
     height: 100%;
     width: 100%;
     border: solid 2px white;
     border-radius: 5px;
 }
 @media screen and (max-width:768px) {
     .cont-map{
         width: auto;
     }
 }