@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,400&display=swap');

*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}
.bg-black-transparent{
  background-color: rgba(0, 0, 0, 0.5);
}

.footerLink{
  text-decoration: none;
  color: white;
  padding: 4px;
}

.footerLink:hover{
  
  color: #991A1E;
}