.popup{
    position: fixed;
    /* width: 100%; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0 , 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: auto;
   
  
}
@media screen and (max-width:768px) {
    .popup{
        flex-direction: row;
       height:auto;
       margin: 0;
      
    }
}

.popup-inner{
    position:relative;
    padding: 50px;
    width: 100%;
    max-width: 740px;
    background: #991A1E;
    border-radius: 10px;

}
@media screen and (max-width:768px) {
    .popup-inner{
        
        width: auto;
        padding: 10px;
    }
}


 .close{
    
    background: #fff;
    color: #fffefe;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor:pointer;
    padding: 15px;
    align-items: flex-end;
    transition: all 0.2s ease-in-out;
    
    
}
.popup-elements{
    text-align: justify;
    margin: 0px;
    color: #fff;
}