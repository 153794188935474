.fm-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:480px) {
    .fm-container{
        max-width: 100%;
        padding: 10px;
        margin: 0;
    }
}
.fm-wrapper{
    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:480px) {
    .fm-wrapper{
        max-width: 100%;
        padding: 0px;
        margin: 0;
    }
}
.fm-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}
.fm-h2{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    text-decoration: underline;
    color:rgb(189, 8, 8);

}
.fm-img{
    height: auto;
    width: 100%;
    margin-bottom: 50px;
}
.fm-span{
    color: rgb(228, 13, 13);
    text-align: 16px;
}
.fm-section{
    margin: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.link-title{
    text-decoration: none;
}
.fm-h3 .link-title{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
}
.fm-text{
    padding: 5px;
    text-align: justify;
}
.fm-list{
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.fm-h3{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.fm-list li{
    cursor: pointer;
    text-decoration: none;
    padding: 3px;
    font-size: 1rem;
    margin-left: 20px;
    color: rgb(189, 8, 8);
}