@import url(https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300;0,400;0,600;1,400&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Dancing+Script:wght@700&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
*{
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif;
}
.bg-black-transparent{
  background-color: rgba(0, 0, 0, 0.5);
}

.footerLink{
  text-decoration: none;
  color: white;
  padding: 4px;
}

.footerLink:hover{
  
  color: #991A1E;
}
:root{
    --primary: #fff;
}
.button{
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 18px;
    color: #f70000;
    cursor: pointer;
    background-color: #fff;
    background-color: var(--primary);

}

.button:hover{
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: #fff;
    border-radius: 4px;
    border: 2px solid #fff;
    border: 2px solid var(--primary);
}
.navbarContainer{
    background: #991A1E;
    width: 100%;
    font-size: 1.2rem;
    padding: 20px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

}

img.logo{
  max-width: 200px;

} 
.nav-item a{
  text-decoration: none;
  color: #fff;
}


  .nav-menu {
    display: grid;
   align-items: center;
    grid-template-columns: repeat(8, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: auto;
    justify-content: end;
    margin-right: 2rem;
  }
  
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
    z-index: 999;
  }
  
  .nav-links {
    color: white;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }
  
  .nav-links:hover {
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    transition: all 0.2s ease-out;
    color: #991A1E;
  }
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 1200px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 85vh;
      position: absolute;
      top: 140px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 999;
   
    }
  
    .nav-menu.active {
      background: #991A1E;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 999;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #fff;
      border-radius: 0;
    }
  
    .navbar-logo {
     
      top: 0;
      left: 0;
      
      /* transform: translate(25%, 50%); */
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top:45px;
      right: 0;
      -webkit-transform: translate(-100%, 60%);
              transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
    .fa-align-left{
        color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile{
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: #fff;
      text-decoration: none;
      color: #DC143C;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: transparent;
      color: #fff;
      transition: 250ms;
      border: solid 2px;
    }
  
    button {
      display: none;
    }
  }

.ddown-menu {
    background:#991A1E;
    width: 300px;
    position: absolute;
    top: 140px;
    list-style: none;
    text-align: start;
    padding: 0 !important;
  }
  
  .ddown-menu li {
    background: #991A1E;
    cursor: pointer;
  }
  
  .ddown-menu li:hover {
    background: #5cabff;
  }
  
  .ddown-menu.clicked {
    display: none;
  }
  
  .ddown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }
 
.cont-container{
    padding: 20px;
    margin: 30px;
   
    display: flex;
    flex-direction: column;
    justify-content: center;
}
@media screen and (max-width:768px) {
    .cont-container{
        padding: 3px;
        margin: 5px;
    }
}

.cont-wrapper{
    width:auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    background: rgb(179, 8, 36);
}
@media screen and (max-width:768px) {
    .cont-wrapper{
        
            flex-direction: column;
            justify-content: center;
            
    }
}
.infowrapper{
   min-width: 400px auto;
   padding: 20px;
}
@media screen and(max-width: 768px) {
    .infowrapper{
        width: auto;
    }
}
.cont-h1{
    color: rgb(252, 252, 252);
    font-size: 50px;
    font-family: 'Anton', sans-serif;
    text-align: center;
 }
 .cont-el{
    display: flex;
    justify-content: center;
    flex-direction: column;
    text-align: center;
    
     
 }
 @media screen and(max-width: 768px) {
    .cont-el{
        width: auto;
    }
}
 .fa-mobile{
     font-size: 1.5rem;
     color: #fff;
     padding: 5px;
 }
 .fa-whatsapp{
    font-size: 1.5rem;
    color: #fff;
    padding: 5px;
 }
 .con-text{
    font-size: 1rem;
     color: #fff;
     padding: 5px;
 }
 .fa-envelope-open-o{

    font-size: 1.5rem;
    color: #fff;
    padding: 5px;
 }
 .fa-map-pin{
    font-size: 1.5rem;
    color: #fff;
    padding: 5px;
 }
 .cont-map{
     
     height: 100%;
     width: 100%;
     border: solid 2px white;
     border-radius: 5px;
 }
 @media screen and (max-width:768px) {
     .cont-map{
         width: auto;
     }
 }
.ab-container{
    display: flex;
    justify-content: center;
    flex-direction: row;
    
    
}
@media screen and (max-width:768px) {
    .ab-container{
        flex-direction: column;
    }
}
.content-wrapper{
    max-width: 800px;
    padding: 20px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:768px) {
    .content-wrapper{
        justify-content: row;
    }
}
.img-wrapper{
   margin-top: 240px;
}
.ab-image{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
    margin-top: 30px;
    margin-bottom: 50px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.title{
    margin: 40px 20px;
    text-align: center;
    font-size: 4rem;
    font-weight: bold;
    font-family: 'Dancing Script', cursive;
    color: rgb(241, 19, 19);;
}
.ab-title{
    text-align: center;
    font-family: 'Dancing Script', cursive;
    font-size: 2rem;
    color: rgb(241, 19, 19);;
}
.ab-text{
    text-align: justify;
    padding: 10px;
    font-size: 1.2rem;
    font-family: 'Barlow Semi Condensed', sans-serif;

}
span{
    font-size: 1.1rem;
    font-weight: bold;
    color:rgb(241, 19, 19);
}
.list{
    display: flex;
    justify-content: center;
    flex-direction: column;
}
.list-item{
    display: inline;
    text-align: justify;
    font-size: 1.2rem;
    color:rgb(241, 19, 19);
    font-weight: bold;
    cursor: pointer;
}
.social-meida-container{
    display: flex;
    justify-content: center;
    margin-bottom: 50px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
}
.icons{
    padding: 20px;
    font-size: 1.5rem;
    color: black;
    margin-left: 10px;
    margin-bottom: 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;
    
}
.icons:hover{
    background: rgb(241, 19, 19);
    color: white;
}
.social-meida-container .icons{
    align-items: center;
}
.fm-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:480px) {
    .fm-container{
        max-width: 100%;
        padding: 10px;
        margin: 0;
    }
}
.fm-wrapper{
    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:480px) {
    .fm-wrapper{
        max-width: 100%;
        padding: 0px;
        margin: 0;
    }
}
.fm-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-text-decoration: underline 5px  ;
            text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}
.fm-h2{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    text-decoration: underline;
    color:rgb(189, 8, 8);

}
.fm-img{
    height: auto;
    width: 100%;
    margin-bottom: 50px;
}
.fm-span{
    color: rgb(228, 13, 13);
    text-align: 16px;
}
.fm-section{
    margin: 10px;
    padding: 20px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.link-title{
    text-decoration: none;
}
.fm-h3 .link-title{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;  
}
.fm-text{
    padding: 5px;
    text-align: justify;
}
.fm-list{
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
.fm-h3{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.fm-list li{
    cursor: pointer;
    text-decoration: none;
    padding: 3px;
    font-size: 1rem;
    margin-left: 20px;
    color: rgb(189, 8, 8);
}
.fee-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center;
}
.fee-wrapper{
    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:768px) {
    .fee-wrapper{
        width: ;
    }
}
.fee-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.fee-section{
    margin: 10px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.fee-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-text-decoration: underline 5px  ;
            text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}
.fee-h2{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.fee-h3{
    text-align: center;
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.fee-text{
     
    text-align: justify;
}
.fee-h5{
    font-size: 1.5rem;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    text-decoration: underline;
    color:rgb(189, 8, 8);
    text-align: center;
}
.b-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center; 
}
.b-wrapper{
    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px; 
}
.b-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-text-decoration: underline 5px  ;
            text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}

.b-img{
    height: auto;
    width: 100%;
    margin-bottom: 50px;
}

.b-table{
    padding: 2px;
    margin-top: 40px;
}
.b-t-section{
    border: solid 1px;
}
.b-t-section th{
    padding: 10px;
    background-color: #991A1E;
    color: #fff;
    font-size: 1.2rem;
}
.b-t-section td{
    border: solid 1px;
    padding: 3px;
    text-align: justify;
    margin-left: 5px;
}
.b-h3{
    padding: 10px;
    margin-bottom: 10px;
    font-size: 1.5rem;
    color: rgb(179, 11, 11);
}
.b-text{
    text-align: justify;
    padding: 10px;
}
.car-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    
}
@media screen and (max-width:768px) {
    .car-container{
        padding: 3px;
        margin: 5px;
    }
}
.car-wrapper{
    width:auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    background: rgb(179, 8, 36);
   
}
@media screen and (max-width:960px) {
    .car-wrapper{
    flex-direction: column;
    justify-content: center;
    width: 100%;
}
}
.car-h1{
    color: rgb(252, 252, 252);
    font-size: 100px;
    font-family: 'Anton', sans-serif;
}
@media screen and (max-width:768px) {
    .car-h1{
    font-size: 50px;

    }
}
.car-content {
    
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 60px;
}
@media screen and (max-width:960px) {
    .car-content{
            padding: 30px;
            justify-content: center;
    }
}
.car-img{
    height: auto;
    width: 100%;
}
.car-img-s{
    height: auto;
    width: 100%;
}

.btn{
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 1.2rem;
    width: 200px;
    align-items: center;
    text-align: center;
}
.car-text{
    margin-top: 30px;
    font-size: 15px;
    font-family: Georgia, 'Times New Roman', Times, serif;
    text-align: justify;
    color: #fff;
}
.car-text-black{
    margin-top: 50px;
    margin-bottom: 20px;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    text-decoration: underline;
    color: rgb(179, 8, 36);
    text-align: center;
}
.car-text-b{
    text-align: center;
}
.im-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-text-decoration: underline 5px  ;
            text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}
.im-title-h2{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    text-decoration: underline;
    color:rgb(189, 8, 8);

}
.immigrate-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:480px) {
    .immigrate-container{
        max-width: 100%;
        padding: 10px;
        margin: 0;
    }
}
.im-image{
    height: auto;
    width: 100%;
    margin-bottom: 50px;
}
.im-infoWrapper{

    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:480px) {
    .im-infoWrapper{
        max-width: 100%;
        padding: 0px;
        margin: 0;
    }
}
.link{
    text-decoration: none;
    font-size: 1rem;
    color: rgb(189, 8, 8);
}
.link:hover{
    color:rgb(255, 0, 0);
}
.im-h3{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.im-text{
    padding: 5px;
}
.im-section{
    margin: 10px;
    padding: 30px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.questions{
    padding: 20px;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;}
.questions li{
  
    cursor: pointer;
    text-decoration: none;
    padding: 3px;
    font-size: 1rem;
    margin-left: 20px;
    color: rgb(189, 8, 8);
   
}
.st-list{
    padding: 5px;
}
.st-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:480px) {
    .st-container{
        max-width: 100%;
        padding: 10px;
        margin: 0;
    }
}
.st-wrapper{
    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:480px) {
    .st-wrapper{
        max-width: 100%;
        padding: 5px;
        margin: 0;
    }
}
.st-img{
    height: auto;
    width: 100%;
    margin-bottom: 50px;
}
.st-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-text-decoration: underline 5px  ;
            text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}
.st-h3{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.st-text{
    padding: 5px;
    text-align: justify;

}
.st-text li{
    margin-bottom: 5px;
    text-align: justify;
}
.r-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:480px) {
    .r-container{
        max-width: 100%;
        padding: 10px;
        margin: 0;
    }
}
.r-wrapper{
    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:480px) {
    .r-wrapper{
        max-width: 100%;
        padding: 0px;
        margin: 0;
    }
}
.r-img{
    height: auto;
    width: 100%;
    margin-bottom: 50px;
}
.r-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-text-decoration: underline 5px  ;
            text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}
.r-h3{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.r-h5{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    text-decoration: underline;
    color:rgb(189, 8, 8);
}
.r-text{
    padding: 5px; 
    text-align: justify;
}
.w-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:480px) {
    .w-container{
        max-width: 100%;
        padding: 10px;
        margin: 0;
    }
}
.w-wrapper{
    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:480px) {
    .w-wrapper{
        max-width: 100%;
        padding: 0px;
        margin: 0;
    }
}

.w-img{
    height: auto;
    width: 100%;
    margin-bottom: 50px;
}

.w-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    -webkit-text-decoration: underline 5px  ;
            text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}
.w-h2{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.w-text{
    padding: 5px; 
    text-align: justify;
}
.w-text-c{
    text-align: center;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 20px;
}
.w-h5{
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    text-decoration: underline;
    color:rgb(189, 8, 8);
    text-align: center;
}
.w-h3{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.title-news{
    font-size: 30px;
    font-weight: 800;
    color: #991A1E;
    text-align: justify;
}
.news-sub-title{
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
}

/* .news-body{
    width: 100%;
    padding: 20px;
    font-weight: 400;
    text-align: justify;
} */
.news-body img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.trading-content{
    width: 100%;
}
.trading-news{
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    min-height: 200px auto;
    padding: 15px;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.trading-news img{
    width: 100px;
    
}
.trading-title{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}
.trading-upper-title{
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 600;
    color:#991A1E ;
}

.sidebar-news{
    margin-top:100%;
}
.main_card{
   height: auto;
}

.container{
    padding: 24px;
    height: 100%;
}
.row_flex{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
}

.card_main{
   width: 100%;
   height: 100% ; 
   position: relative;
   cursor: pointer;

}

 .card_img{
    display: block;
    width: 100%;
    height:100%;
}
 .caption_box{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(180deg,rgba(3,5,66,.0001) 0%,#303252 100%);
    z-index: 20;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 20px;
    transition: 0.15s ease-in;
    
}

.main_card h3{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.side_card{
position: block;
width: 100%;
height: 100% ;

}
.paragraph{
    font-size: 10px;
}
.card{
    height: 49.5%;
    margin-bottom:0.5rem;
    border-radius: 4px;
    border: 1px solid #DDDDF0;
    overflow: hidden;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    grid-gap: 30px;
    gap: 30px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    cursor: pointer;
}
.side_card .card .side_card_catption_box{
    position: absolute;
    bottom: 0;
    height: 50%;
    width:100%;
    background: linear-gradient(180deg,rgba(3,5,66,.0001) 0%,#303252 100%);
    z-index: 20;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 16px;
    transition: 0.15s ease-in;
   
}

.card-main-wrapper{
height: 100%;
 display: grid;
 grid-template-columns: 3fr 1fr;
 margin-top: 50px;
 
}
.side_card_catption_box h3{
     font-size: 1rem;
     font-weight: 400;
}
.side_card_catption_box p{
    font-size: 0.5rem;
    font-weight: 400;
}

.card-mai-content{
    width: 85%;
    height: 220px;
    display: flex;
    flex-direction: row;
    border:1px solid #DDDDF0;
    border-radius: 4px;
    margin-top: 15px;
    cursor: pointer;
}

.card-content-wrapper{
    width: 100%;
 
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    padding: 10px;
}
.main-content-img{
    width:250px;
    height: 200px;
}

.card-main-content-box{
    padding: 16px;
}

.subscribe_box {
    height: 300px;
    padding-top: 18px;
    background: #030542;
    border: 1px solid #DDDDF0;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #fff;
    align-items: center;

}
.btn{
    width: 100%;
}

/* Mobile Responsive */

@media screen and (max-width: 600px)  {
    .row_flex{
        display: flex;
        flex-direction: column;  
        grid-column-gap: 10px;  
        -webkit-column-gap: 10px;  
                column-gap: 10px; 
    }
    .caption_box h3{
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    .paragraph{
        font-size: 0.5rem;
    }
}

.body-container{
    /* margin: 0; */
    background-color: #f8f9fb;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-left: auto;
    margin-right: auto;

}
@media screen and (max-width:768px) {
    .body-container{
        height: auto;
        flex-direction: column;
        justify-content: center;
    }
}
.b-h1{
    text-align: justify;
    color: #991A1E;
    font-size: 3rem;
    font-family:'Open Sans', sans-serif; 
    font-weight: 800;
}
.price-wrapper-book{
    width: 800px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
@media screen and (max-width:768px) {
    .price-wrapper-book{
        flex-direction: column;
        
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

.price-column{
    background-color: white;
    box-shadow: 0 7px 30px rgba(52,31,97, 0.1);
    padding: 2rem;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 500px;
    border-radius: 8px;

}
.booking-link{
    text-decoration: none;
    color: #fff;
}
.booking-link .pp{
    color: #991A1E;
} 
@media screen and (max-width:768px) {
    .price-column{
        width: 300px;
        margin-top: 20px;
        padding: 3rem;
        margin-bottom: 20px;
    }
}
.price-column.popular{
    background-color:#991A1E ;
    box-shadow: 0 7px 30px rgba(52,31,97, 0.5);
    color: rgb(250, 250, 250);
    margin-top: -1.5rem;
    padding-top: 3.5rem;
    margin-bottom: -1.5rem;
    padding-bottom: 3.5rem;
}
.price-column:first-child{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.price-column:last-child{
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}
.price-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bold;
}
.price{
    font-size: 3.5rem;
    display: flex;
}
.dollar-sign{
    font-size: 1.5rem;
    margin-top: .5rem;
    margin-right: .25rem;
}
.per-month{
    font-size: .75rem;
    align-self: flex-end;
    margin-top: 1.1rem;
    text-transform: uppercase;
}
.plan-name{
    text-transform: uppercase;
    font-size: .9rem;
    margin-top: 1rem;
    margin-bottom: 0;

}
.divider{
    height: 1px;
    width: 100%;
    background: rgba(0,0,0, .3);
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.price-column.popular .divider{
    background-color: rgba(255, 255,255, 0.2);
}
.feature{
    background: white;
}
.read-button{
 
    background: #991A1E;
    width: 100%;
    color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor:pointer;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: all 0.2s ease-in-out;

}
.read-button:hover{
    background: #042058;
}
.price-column.popular .read-button.popular{
    text-decoration: none;
    background: #fff;
    width: 100%;
    color: #991A1E;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor:pointer;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: all 0.2s ease-in-out;
}
.text-element{
    width: auto;
    margin-top:50px;
}

.text-elemt{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    padding: 10px;
    margin-bottom: 50px;
}
.feature {
    display: flex;
    justify-content: flex-start;
}
.booking-text .popular{
    background: #991A1E;
    height: 100%;
    padding: 5px;
}

.feature.popular{
    display: flex;
    justify-content: flex-start;
    background: #991A1E;
}
.f-element{
    background: #991A1E;
}
.fa-circle{
    padding: 8px;
    color: rgb(9, 180, 9);
}
.text-fe{
    
    padding: 3px;
    margin-bottom: 5px;
    text-align: justify;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
}

.popup{
    position: fixed;
    /* width: 100%; */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0 , 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    margin-top: 50px;
    margin-bottom: 50px;
    overflow: auto;
   
  
}
@media screen and (max-width:768px) {
    .popup{
        flex-direction: row;
       height:auto;
       margin: 0;
      
    }
}

.popup-inner{
    position:relative;
    padding: 50px;
    width: 100%;
    max-width: 740px;
    background: #991A1E;
    border-radius: 10px;

}
@media screen and (max-width:768px) {
    .popup-inner{
        
        width: auto;
        padding: 10px;
    }
}


 .close{
    
    background: #fff;
    color: #fffefe;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor:pointer;
    padding: 15px;
    align-items: flex-end;
    transition: all 0.2s ease-in-out;
    
    
}
.popup-elements{
    text-align: justify;
    margin: 0px;
    color: #fff;
}
