
.body-container{
    /* margin: 0; */
    background-color: #f8f9fb;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    
    margin-left: auto;
    margin-right: auto;

}
@media screen and (max-width:768px) {
    .body-container{
        height: auto;
        flex-direction: column;
        justify-content: center;
    }
}
.b-h1{
    text-align: justify;
    color: #991A1E;
    font-size: 3rem;
    font-family:'Open Sans', sans-serif; 
    font-weight: 800;
}
.price-wrapper-book{
    width: 800px;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
@media screen and (max-width:768px) {
    .price-wrapper-book{
        flex-direction: column;
        
        width: auto;
        margin-left: auto;
        margin-right: auto;
    }
}

.price-column{
    background-color: white;
    box-shadow: 0 7px 30px rgba(52,31,97, 0.1);
    padding: 2rem;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 500px;
    border-radius: 8px;

}
.booking-link{
    text-decoration: none;
    color: #fff;
}
.booking-link .pp{
    color: #991A1E;
} 
@media screen and (max-width:768px) {
    .price-column{
        width: 300px;
        margin-top: 20px;
        padding: 3rem;
        margin-bottom: 20px;
    }
}
.price-column.popular{
    background-color:#991A1E ;
    box-shadow: 0 7px 30px rgba(52,31,97, 0.5);
    color: rgb(250, 250, 250);
    margin-top: -1.5rem;
    padding-top: 3.5rem;
    margin-bottom: -1.5rem;
    padding-bottom: 3.5rem;
}
.price-column:first-child{
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.price-column:last-child{
    border-top-left-radius: 0;
    border-bottom-right-radius: 0;
}
.price-header{
    display: flex;
    flex-direction: column;
    align-items: center;
    font-family:Cambria, Cochin, Georgia, Times, 'Times New Roman', serif;
    font-weight: bold;
}
.price{
    font-size: 3.5rem;
    display: flex;
}
.dollar-sign{
    font-size: 1.5rem;
    margin-top: .5rem;
    margin-right: .25rem;
}
.per-month{
    font-size: .75rem;
    align-self: flex-end;
    margin-top: 1.1rem;
    text-transform: uppercase;
}
.plan-name{
    text-transform: uppercase;
    font-size: .9rem;
    margin-top: 1rem;
    margin-bottom: 0;

}
.divider{
    height: 1px;
    width: 100%;
    background: rgba(0,0,0, .3);
    margin-top: 2rem;
    margin-bottom: 2rem;
}
.price-column.popular .divider{
    background-color: rgba(255, 255,255, 0.2);
}
.feature{
    background: white;
}
.read-button{
 
    background: #991A1E;
    width: 100%;
    color: #fff;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor:pointer;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: all 0.2s ease-in-out;

}
.read-button:hover{
    background: #042058;
}
.price-column.popular .read-button.popular{
    text-decoration: none;
    background: #fff;
    width: 100%;
    color: #991A1E;
    border-radius: 5px;
    outline: none;
    border: none;
    cursor:pointer;
    display:flex;
    justify-content: center;
    align-items: center;
    padding: 15px;
    transition: all 0.2s ease-in-out;
}
.text-element{
    width: auto;
    margin-top:50px;
}

.text-elemt{
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    margin-top: 50px;
    padding: 10px;
    margin-bottom: 50px;
}
.feature {
    display: flex;
    justify-content: flex-start;
}
.booking-text .popular{
    background: #991A1E;
    height: 100%;
    padding: 5px;
}

.feature.popular{
    display: flex;
    justify-content: flex-start;
    background: #991A1E;
}
.f-element{
    background: #991A1E;
}
.fa-circle{
    padding: 8px;
    color: rgb(9, 180, 9);
}
.text-fe{
    
    padding: 3px;
    margin-bottom: 5px;
    text-align: justify;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
    font-size: 1.2rem;
    font-weight: bold;
}
