.title-news{
    font-size: 30px;
    font-weight: 800;
    color: #991A1E;
    text-align: justify;
}
.news-sub-title{
    font-size: 20px;
    font-weight: 600;
    margin-top: 20px;
}

/* .news-body{
    width: 100%;
    padding: 20px;
    font-weight: 400;
    text-align: justify;
} */
.news-body img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.trading-content{
    width: 100%;
}
.trading-news{
    width: 100%;
    height: auto;
    overflow: hidden;
    display: flex;
    min-height: 200px auto;
    padding: 15px;
    justify-content: space-between;
    cursor: pointer;
    margin-top: 15px;
    box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}
.trading-news img{
    width: 100px;
    
}
.trading-title{
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
}
.trading-upper-title{
    text-transform: uppercase;
    font-size: 25px;
    font-weight: 600;
    color:#991A1E ;
}

.sidebar-news{
    margin-top:100%;
}