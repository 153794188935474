.st-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center;
}
@media screen and (max-width:480px) {
    .st-container{
        max-width: 100%;
        padding: 10px;
        margin: 0;
    }
}
.st-wrapper{
    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:480px) {
    .st-wrapper{
        max-width: 100%;
        padding: 5px;
        margin: 0;
    }
}
.st-img{
    height: auto;
    width: 100%;
    margin-bottom: 50px;
}
.st-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}
.st-h3{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.st-text{
    padding: 5px;
    text-align: justify;

}
.st-text li{
    margin-bottom: 5px;
    text-align: justify;
}