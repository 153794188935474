.main_card{
   height: auto;
}

.container{
    padding: 24px;
    height: 100%;
}
.row_flex{
    display: grid;
    grid-template-columns: 2fr 1fr;
    grid-gap: 20px;
}

.card_main{
   width: 100%;
   height: 100% ; 
   position: relative;
   cursor: pointer;

}

 .card_img{
    display: block;
    width: 100%;
    height:100%;
}
 .caption_box{
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 40%;
    background: linear-gradient(180deg,rgba(3,5,66,.0001) 0%,#303252 100%);
    z-index: 20;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 16px 20px;
    transition: 0.15s ease-in;
    
}

.main_card h3{
    font-size: 1.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
}

.side_card{
position: block;
width: 100%;
height: 100% ;

}
.paragraph{
    font-size: 10px;
}
.card{
    height: 49.5%;
    margin-bottom:0.5rem;
    border-radius: 4px;
    border: 1px solid #DDDDF0;
    overflow: hidden;
    position: relative;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    gap: 30px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    cursor: pointer;
}
.side_card .card .side_card_catption_box{
    position: absolute;
    bottom: 0;
    height: 50%;
    width:100%;
    background: linear-gradient(180deg,rgba(3,5,66,.0001) 0%,#303252 100%);
    z-index: 20;
    color: #fff;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    padding: 15px 16px;
    transition: 0.15s ease-in;
   
}

.card-main-wrapper{
height: 100%;
 display: grid;
 grid-template-columns: 3fr 1fr;
 margin-top: 50px;
 
}
.side_card_catption_box h3{
     font-size: 1rem;
     font-weight: 400;
}
.side_card_catption_box p{
    font-size: 0.5rem;
    font-weight: 400;
}

.card-mai-content{
    width: 85%;
    height: 220px;
    display: flex;
    flex-direction: row;
    border:1px solid #DDDDF0;
    border-radius: 4px;
    margin-top: 15px;
    cursor: pointer;
}

.card-content-wrapper{
    width: 100%;
 
    display: flex;
    flex-direction: row;
    justify-content:space-between;
    padding: 10px;
}
.main-content-img{
    width:250px;
    height: 200px;
}

.card-main-content-box{
    padding: 16px;
}

.subscribe_box {
    height: 300px;
    padding-top: 18px;
    background: #030542;
    border: 1px solid #DDDDF0;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    color: #fff;
    align-items: center;

}
.btn{
    width: 100%;
}

/* Mobile Responsive */

@media screen and (max-width: 600px)  {
    .row_flex{
        display: flex;
        flex-direction: column;  
        column-gap: 10px; 
    }
    .caption_box h3{
        font-size: 1rem;
        margin-bottom: 1rem;
    }
    .paragraph{
        font-size: 0.5rem;
    }
}