.ddown-menu {
    background:#991A1E;
    width: 300px;
    position: absolute;
    top: 140px;
    list-style: none;
    text-align: start;
    padding: 0 !important;
  }
  
  .ddown-menu li {
    background: #991A1E;
    cursor: pointer;
  }
  
  .ddown-menu li:hover {
    background: #5cabff;
  }
  
  .ddown-menu.clicked {
    display: none;
  }
  
  .ddown-link {
    display: block;
    height: 100%;
    width: 100%;
    text-decoration: none;
    color: #fff;
    padding: 16px;
  }
  
  @media screen and (max-width: 960px) {
    .fa-caret-down {
      display: none;
    }
  }
 