.fee-container{
    padding: 20px;
    margin: 30px;
    display: flex;
    justify-content: center;
}
.fee-wrapper{
    max-width: 1200px;
    padding: 30px;
    margin: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    box-shadow: rgba(0, 0, 0, 0.45) 0px 25px 20px -20px;
}
@media screen and (max-width:768px) {
    .fee-wrapper{
        width: ;
    }
}
.fee-img{
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 50%;
}
.fee-section{
    margin: 10px;
    padding: 15px;
    box-shadow: rgba(0, 0, 0, 0.02) 0px 1px 3px 0px, rgba(27, 31, 35, 0.15) 0px 0px 0px 1px;
}
.fee-title{
    text-align: center;
    margin-bottom: 50px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    text-decoration: underline 5px  ;
    padding: 5px ;
    color:rgb(189, 8, 8);
}
.fee-h2{
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.fee-h3{
    text-align: center;
    color: rgb(189, 8, 8);
    margin-top: 20px;
    padding: 5px;
    font-size: 1.4rem;
    font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
}
.fee-text{
     
    text-align: justify;
}
.fee-h5{
    font-size: 1.5rem;
    font-family:'Segoe UI', Tahoma, Geneva, Verdana, sans-serif ;
    text-decoration: underline;
    color:rgb(189, 8, 8);
    text-align: center;
}